<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-toolbar dense>
          <choose-map
            :key="ts"
            @change="loadSelectedMap"
            v-model="selectedMap"
          />
          <upload-map :key="ts" @uploaded="mapAdded" />

          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn-toggle v-model="selectMode">
            <v-btn icon>
              <v-icon>mdi-cursor-default-outline</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-shape-rectangle-plus</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-divider v-if="selectedMap" class="mx-4" inset vertical></v-divider>
          <v-btn v-if="selectedMap" @click="deleteSelectedMap" icon>
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
          <v-spacer />
          <v-switch
            hide-details
            flat
            v-model="showAnnotations"
            label="Show Zones"
          ></v-switch>
        </v-toolbar>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col lg="8" sm="12" style="text-align: center">
        <div class="mb-2 text-caption">Scale {{ scale | formatPercent }}</div>
        <div
          ref="canvasContainer"
          v-on:keydown.delete="delAnnotation"
          tabindex="1"
          id="editzone"
          style="position: relative; border: 1px solid #ccc"
        >
          <v-img
            :contain="false"
            @load="setKonvasDimensions"
            :key="ts"
            v-if="selectedMap"
            :src="selectedMap.src"
            :width="selectedMap.mapWidth * scale"
            :height="selectedMap.mapHeight * scale"
          />

          <v-stage
            @mousedown="handleStageMouseDown"
            @touchstart="handleStageMouseDown"
            @mousemove="dragMove"
            @mouseup="handleStageMouseUp"
            @mouseenter="enterStage"
            @mouseleave="leaveStage"
            ref="stage"
            style="position: absolute; top: 0; left: 0"
            :config="configKonva"
          >
            <v-layer ref="annotationLayer" v-if="showAnnotations">
              <v-group
                @transformend="handleTransformEnd"
                @dragend="dragEnd"
                v-for="rect in layerAnnotations"
                :key="rect.id"
                :config="{ x: rect.x, y: rect.y, draggable: true }"
              >
                <v-text
                  :config="{
                    text: rect.name,
                    x: 10,
                    y: 10,
                    fontStyle: 'bold',
                    fontSize: 14,
                  }"
                ></v-text>
                <v-rect
                  @keydown="delAnnotation"
                  @mouseenter="enterRect"
                  @mouseleave="leaveRect"
                  @dblclick="
                    assignLocation = null;
                    showEditAnnotation = true;
                  "
                  :config="{
                    name: rect.name,
                    width: rect.width,
                    height: rect.height,
                    stroke: rect.rect,
                    strokeWidth: rect.strokeWidth,
                    fill: rect.fill,
                    opacity: rect.opacity,
                    drawBorder: true,
                  }"
                ></v-rect>
              </v-group>
              <v-transformer
                ref="transformer"
                :config="{ rotateEnabled: false }"
              />
            </v-layer>
          </v-stage>
        </div>
      </v-col>
      <v-col lg="4" sm="12">
        <div class="ml-5">
          <div class="text-center text-caption">
            {{ layerAnnotations ? layerAnnotations.length : 0 }} Zones
          </div>
          <div :style="`height: ${configKonva.height}px; overflow-y: scroll;`">
            <v-card
              :color="selectedAnnotationName == annotation.name ? 'blue lighten-4':''"
              @click="
                selectedAnnotationName = annotation.name;
                updateTransformer();
              "
              class="mt-5"
              v-for="annotation in layerAnnotations"
              :key="annotation.id"
            >
              <v-card-actions class="justify-end">
                <v-spacer />
                <v-btn
                  @click="
                    selectedAnnotationName = annotation.name;
                    assignLocation = null;
                    showEditAnnotation = true;
                  "
                  icon
                >
                  <v-icon>mdi-circle-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  @click="
                    selectedAnnotationName = annotation.name;
                    delAnnotation();
                  "
                  icon
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-title>
                {{ annotation.name }}
              </v-card-title>
              <v-card-subtitle class="d-flex justify-space-between">
                <div>
                  width:
                  {{
                    parseFloat(
                      annotation.width *
                        (annotation.scaleX ? annotation.scaleX : 1)
                    ).toFixed(0)
                  }}
                  , height:
                  {{
                    parseFloat(
                      annotation.height *
                        (annotation.scaleY ? annotation.scaleY : 1)
                    ).toFixed(0)
                  }}
                </div>
                <div>
                  x:{{ parseFloat(annotation.x).toFixed(0) }} , y:
                  {{ parseFloat(annotation.y).toFixed(0) }}
                </div>
                <!-- <div>
                  scaleX: {{ annotation.scaleX }} scaleY: {{ annotation.scaleY }}
                </div> -->
              </v-card-subtitle>
              <v-card-text>Location {{ annotation.locationName }} </v-card-text>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="showEditAnnotation" width="450">
      <v-card v-if="selectedAnnotation" class="py-10">
        <v-card-title
          >Assign location to zone {{ selectedAnnotationName }}</v-card-title
        >
        <v-card-text>
          <v-autocomplete
            v-model="assignLocation"
            :items="locations"
            label="Available Locations"
            item-text="name"
            item-value="id"
            return-object
            clearable
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="updateZoneLocation">Update</v-btn>
          <v-btn @click="showEditAnnotation = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="!selectedAnnotation" class="py-10">
        <v-card-title>No Zone Selected</v-card-title>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import VueKonva from "vue-konva";
import Konva from "konva";
import {
  deleteMap,
  getMapZones,
  addZone,
  updateZone,
  deleteZone,
} from "@/services/mapServices";
const ChooseMap = () => import("@/components/map/ChooseMap.vue");
const UploadMap = () => import("@/components/map/UploadMap.vue");

Vue.use(VueKonva);

export default {
  data() {
    return {
      ts: Date.now(),
      selectedMap: null,
      ctx: null,
      selectMode: 0,
      showEditAnnotation: false,
      addingRect: false,
      scale: 1,
      startPosition: {
        x: null,
        y: null,
      },
      configKonva: {
        width: 740,
        height: 1200,
      },
      selectedAnnotationName: null,
      showAnnotations: true,
      assignLocation: null,
      layerAnnotations: [],
      layerTexts: [],
      rectTemplate: {
        stroke: "#555",
        strokeWidth: 1,
        opacity: 0.5,
        shadowColor: "black",
        shadowBlur: 10,
        shadowOffsetX: 10,
        shadowOffsetY: 10,
        shadowOpacity: 0.2,
        cornerRadius: 5,
        drawBorder: true,
      },
    };
  },
  watch: {
    showAnnotations(show) {
      if (show) {
        this.renderAnnotationsLayer();
      }
    },
  },
  components: {
    ChooseMap,
    UploadMap,
  },
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    ...mapGetters("locations", ["locations"]),

    selectedAnnotation() {
      if (this.layerAnnotations) {
        const index = this.layerAnnotations.findIndex(
          (r) => r.name === this.selectedAnnotationName
        );
        if (index < 0) {
          return null;
        }
        return this.layerAnnotations[index];
      }
    },
    stage() {
      if (this.$refs.stage) {
        return this.$refs.stage.getStage();
      }
    },
  },
  methods: {
    ...mapActions("messages", ["addSuccessMessage", "addErrorMessage"]),
    ...mapActions("locations", ["loadLocations"]),

    // syncAnnotationPosition(rects) {
    //   //update root data, sync position with main datasource
    //   const selectedIndex = this.allPagesAnnotations.findIndex(
    //     (item) => item.page == this.currentPage
    //   );

    //   //skip if no page is selected
    //   if (selectedIndex < 0) {
    //     return;
    //   }

    //   const selectedPage = this.allPagesAnnotations[selectedIndex];

    //   rects.forEach((rect) => {
    //     const annotationIndex = selectedPage.annotations.findIndex(
    //       (q) => q.number === rect.number
    //     );

    //     const x = rect.x;
    //     const y = rect.y;
    //     const width = rect.width * (rect.scaleX || 1);
    //     const height = rect.height * (rect.scaleY || 1);

    //     //Add question if not exist
    //     if (annotationIndex < 0) {
    //       const question = "N/A";
    //       const newQuestion = {
    //         question,
    //         number: rect.number,
    //         id: rect.id,
    //         position: { x, y, width, height },
    //       };
    //       selectedPage.annotations.push(newQuestion);
    //     } else {
    //       // find question
    //       selectedPage.annotations[annotationIndex].position.x = x;
    //       selectedPage.annotations[annotationIndex].position.y = y;
    //       selectedPage.annotations[annotationIndex].position.width = width;
    //       selectedPage.annotations[annotationIndex].position.height = height;
    //     }
    //   });

    //   //Redraw layer
    //   if (this.$refs.annotationLayer) {
    //     this.$refs.annotationLayer.getNode().draw();
    //   }
    // },
    /**
     * Get zones for selected map and render layer over map
     */
    async renderAnnotationsLayer() {
      //Reset layer
      this.layerAnnotations = [];
      if (this.selectedMap) {
        const mapZones = await getMapZones(this.selectedMap.id).then(
          (resp) => resp.data
        );
        if (mapZones && mapZones.length > 0) {
          //map zones to rects and text layer
          const rects = mapZones.map((z) => {
            return Object.assign(
              {
                name: `zone-${z.locationName ? z.locationName : z.id}`,
                locationId: z.locationId,
                locationName: z.locationName,
                id: z.id,
                x: z.positionX,
                y: z.positionY,
                width: z.width,
                height: z.height,
                fill: z.color ? z.color : Konva.Util.getRandomColor(),
              },
              this.rectTemplate
            );
          });

          this.layerAnnotations = rects;
        }
      }
    },
    dragMove() {
      if (this.selectMode === 1 && this.addingRect) {
        const stage = this.$refs.stage;
        const mousePos = stage.getNode().getPointerPosition();

        //expand block size based on mouse position
        if (this.selectedAnnotationName) {
          const rectIndex = this.layerAnnotations.findIndex(
            (r) => r.name === this.selectedAnnotationName
          );
          if (rectIndex > -1) {
            const rect = this.layerAnnotations[rectIndex];
            rect.width = mousePos.x / this.scale - rect.x;
            rect.height = mousePos.y / this.scale - rect.y;
            //update layer
            this.layerAnnotations.splice(rectIndex, 1, rect);
          }
        }
        //Create new rect
        else {
          let newRect = Object.assign({}, this.rectTemplate);
          const newIndex = this.layerAnnotations.length + 1;
          newRect.name = "New Zone " + newIndex;
          newRect.number = newIndex;
          newRect.x = this.startPosition.x / this.scale;
          newRect.y = this.startPosition.y / this.scale;
          newRect.width = 100 * this.scale;
          newRect.height = 50 * this.scale;
          newRect.fill = Konva.Util.getRandomColor();
          this.layerAnnotations.push(newRect);
          this.selectedAnnotationName = newRect.name;
        }
      }
    },
    dragEnd(e) {
      const group = e.target;
      const groupRect = group.children[1];
      const newPos = groupRect.getAbsolutePosition();
      // Find element in stage and update new position
      const rectIndex = this.layerAnnotations.findIndex(
        (r) => r.name === this.selectedAnnotationName
      );

      const rect = this.layerAnnotations[rectIndex];
      rect.x = newPos.x / this.scale;
      rect.y = newPos.y / this.scale;

      this.layerAnnotations.splice(rectIndex, 1, rect);
      this.updateSelectedZone(rect);
    },
    handleStageMouseUp() {
      if (this.addingRect) {
        // this.selectedAnnotationName = null;
        if (this.selectedAnnotationName) {
          this.assignLocation = null;
          this.showEditAnnotation = true;
          const rectIndex = this.layerAnnotations.findIndex(
            (r) => r.name === this.selectedAnnotationName
          );

          const rect = this.layerAnnotations[rectIndex];

          // Save zone if no id is assigned
          if (!rect.id) {
            this.saveAnnotation(rect);
          }
        }
        this.addingRect = false;
      }
    },
    handleTransformEnd(e) {
      if (!this.selectedAnnotationName) {
        return;
      }

      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      const rect = this.layerAnnotations.find(
        (r) => r.name === this.selectedAnnotationName
      );

      //skip if not found
      if (!rect) {
        return;
      }

      const group = e.target;
      // const groupRect = group.children[1];

      // update the state
      rect.x = group.x();
      rect.y = group.y();
      rect.rotation = group.rotation();
      rect.scaleX = group.scaleX();
      rect.scaleY = group.scaleY();

      // change fill
      rect.fill = Konva.Util.getRandomColor();

      //Update layerAnnotations with new values, to trigger watch on the variable
      this.layerAnnotations.splice(
        this.layerAnnotations.findIndex(
          (r) => r.name === this.selectedAnnotationName
        ),
        1,
        rect
      );

      this.updateSelectedZone(rect);
    },
    handleStageMouseDown(e) {
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedAnnotationName = "";
        this.updateTransformer();

        if (this.selectMode === 1) {
          // new selection mode started
          this.addingRect = true;

          const stage = this.$refs.stage;
          const mousePos = stage.getNode().getPointerPosition();
          this.startPosition.x = parseInt(mousePos.x);
          this.startPosition.y = parseInt(mousePos.y);
        }

        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === "Transformer";
      if (clickedOnTransformer) {
        return;
      }

      // find clicked rect by its name
      const name = e.target.name();
      const rect = this.layerAnnotations.find((r) => r.name === name);
      if (rect) {
        this.selectedAnnotationName = name;
      } else {
        this.selectedAnnotationName = "";
      }
      this.updateTransformer();
    },
    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedAnnotationName } = this;

      const selectedNode = stage.findOne("." + selectedAnnotationName);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        const group = selectedNode.parent;
        // attach to another node
        transformerNode.nodes([group]);
      } else {
        // remove transformer
        transformerNode.detach();
      }
      transformerNode.getLayer().batchDraw();
    },
    enterStage() {
      const stage = this.$refs.stage;
      if (this.selectMode === 1) {
        stage.getNode().container().style.cursor = "crosshair";
      } else {
        stage.getNode().container().style.cursor = "pointer";
      }
    },
    leaveStage() {
      const stage = this.$refs.stage;
      stage.getNode().container().style.cursor = "default";
    },
    enterRect() {
      const stage = this.$refs.stage;
      stage.getNode().container().style.cursor = "move";
    },
    leaveRect() {
      const stage = this.$refs.stage;
      stage.getNode().container().style.cursor = "default";
    },
    delAnnotation() {
      if (this.selectedAnnotationName) {
        const index = this.layerAnnotations.findIndex(
          (r) => r.name === this.selectedAnnotationName
        );
        const rect = this.layerAnnotations[index];
        if (rect && rect.id) {
          deleteZone(rect.id);
        }

        if (index >= 0) {
          //remove selected and transformer
          this.selectedAnnotationName = null;
          const transformerNode = this.$refs.transformer.getNode();
          transformerNode.detach();

          this.layerAnnotations.splice(index, 1);
        }
      }
    },
    async updateZoneLocation() {
      if (!this.assignLocation || !this.assignLocation.id) {
        this.addErrorMessage("Please choose a location");
        return;
      }

      //check if selected location is already used
      const usedZoned = this.layerAnnotations.filter(
        (z) =>
          z.name != this.selectedAnnotationName &&
          z.locationId == this.assignLocation.id
      );
      if (usedZoned && usedZoned.length > 0) {
        this.addErrorMessage(
          `${this.assignLocation.name} is already used by ${usedZoned[0].name}`
        );
        return;
      }

      this.showEditAnnotation = false;

      const index = this.layerAnnotations.findIndex(
        (r) => r.name === this.selectedAnnotationName
      );
      const rect = this.layerAnnotations[index];
      rect.locationName = this.assignLocation.name;
      rect.locationId = this.assignLocation.id;
      // this.$set(this.layerAnnotations, index, rect)
      await this.updateSelectedZone(rect);

      this.renderAnnotationsLayer();
    },
    setAnnotation(annotation) {
      console.log(`set annotation ${JSON.stringify(annotation)}`);
    },
    loadSelectedMap(map) {
      const src = `${process.env.VUE_APP_BASE_URL}/file/map/${map.fileId}`;
      this.selectedMap.src = src;

      if (this.showAnnotations) {
        this.renderAnnotationsLayer();
      }
    },
    deleteSelectedMap() {
      if (this.selectedMap && this.selectedMap.id) {
        deleteMap(this.selectedMap.id).then(() => {
          this.addSuccessMessage("Map deleted successfully");
          this.ts = Date.now();
          this.selectedMap = null;
        });
      }
    },
    mapAdded(map) {
      this.ts = Date.now();
      this.selectedMap = map;
      this.loadSelectedMap(map);
    },
    setKonvasDimensions() {
      const imgDiv = this.$refs.canvasContainer;
      if (imgDiv && this.selectedMap) {
        this.scale = imgDiv.clientWidth / this.selectedMap.mapWidth;
        this.configKonva.width = imgDiv.clientWidth;
        this.configKonva.height = this.selectedMap.mapHeight * this.scale;

        if (this.$refs.stage) {
          this.$refs.stage.getStage().scale({ x: this.scale, y: this.scale });
        }
        //loop through all zones and update to scale
        // this.layerAnnotations.forEach(z => {
        //   z.width = z.width * this.scale;
        //   // debugger;
        //   // z.height = z.height * this.scale;
        // })
      }
    },
    saveAnnotation(rect) {
      const params = {
        locationId: rect.locationId,
        positionX: rect.x,
        positionY: rect.y,
        width: rect.width,
        height: rect.height,
        color: rect.fill,
        mapId: this.selectedMap.id,
      };
      addZone(params).then((resp) => {
        this.addSuccessMessage("Zone saved");
        rect.id = resp.data.id;
      });
    },
    async updateSelectedZone(rect) {
      if (rect && rect.id) {
        const zone = {
          id: rect.id,
          locationId: rect.locationId,
          positionX: rect.x,
          positionY: rect.y,
          width: rect.width * (rect.scaleX ? rect.scaleX : 1),
          height: rect.height * (rect.scaleY ? rect.scaleY : 1),
          color: rect.fill,
          mapId: this.selectedMap.id,
        };

        await updateZone(zone);
      }
    },
  },
  mounted: function () {
    this.loadLocations();
    window.addEventListener("resize", this.setKonvasDimensions);
    if (this.showAnnotations) {
      this.renderAnnotationsLayer();
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.setKonvasDimensions);
  },
};
</script>

<style>
</style>
