
    import Vue from "vue";

    const END_POINT = "/map";

    const getMap = id => Vue.axios.get(END_POINT + "/" + id);

    const getMapZones = id => Vue.axios.get(END_POINT + "/" + id + "/zones");

    const addZone = data => Vue.axios.post("/mapLocation/",  data);

    const updateZone = zone => Vue.axios.put("/mapLocation/"+zone.id, zone);

    const deleteZone = zoneId => Vue.axios.delete("/mapLocation/" + zoneId);

    const getAllMaps = data => Vue.axios.get(END_POINT, {params: data});

    const createMap = map => Vue.axios.post(END_POINT,  map );

    const updateMap = map => Vue.axios.put(END_POINT  + "/" + map.id,  map );

    const deleteMap = mapId => Vue.axios.delete(END_POINT  + "/" + mapId);


    export {
      getMap,
      getMapZones,
      addZone,
      updateZone,
      deleteZone,
      getAllMaps,
      createMap,
      updateMap,
      deleteMap,
    };
    